import * as tslib_1 from "tslib";
import { NULL_SELECTED_VALUE } from '@agent-ds/shared/constants/consts';
import { ClickOutsideDirective } from '@agent-ds/shared/directives/click-outside.directive';
import { isNestedSupplied, SupplierCallType } from '@agent-ds/shared/models';
import { typeOf } from '@agent-ds/shared/pipes/typeof.pipe';
import { deepCompare, getValueFromObject } from '@agent-ds/shared/util/util';
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { ControlValueAccessor, FormControl, ValidationErrors, Validator } from '@angular/forms';
import { Observable } from 'rxjs';
export class AutocompleteComponent {
    constructor(cdr) {
        this.cdr = cdr;
        this.hideBtn = true;
        this.filters = [];
        this.valueChange = new EventEmitter();
        this.optionsChange = new EventEmitter();
        this.innerOptions = [];
        this.filteredOptions = [];
        this.showCheckbox = false;
        this.showTooltipToEnter = false;
        this.closed = true;
        this.selectedItems = [];
        this.everSelectedItems = [];
        this.filterValues = {};
        this.transparentFilterValues = {};
        this.singleSelectedLabel = '';
        this.getValueFromObject = getValueFromObject;
        this.alive = true;
        this.propagateChange = () => this.valueChange.emit(this.valueInner);
        this.propagateTouch = () => false;
    }
    get options() {
        return this.innerOptions;
    }
    set options(options) {
        this.innerOptions = options || [];
        if (!this.everSelectedItems.length) {
            this.everSelectedItems.push(...this.innerOptions);
        }
        this.onFilterChange(null, null);
        this.updateSelected();
    }
    get value() {
        return this.valueInner;
    }
    set value(value) {
        this.valueInner = value;
        this.updateSelected();
    }
    get hasOptionHeader() {
        return Array.isArray(this.labelField) && this.labelField.find((field) => field.title != null) != null;
    }
    get optionTemplateArray() {
        return Array.isArray(this.labelField) ? this.labelField.filter((item) => !item.hidden) : [{ name: this.labelField }];
    }
    ngOnInit() {
        this.alive = true;
        this.clickDir.detach();
        if (!this.value) {
            this.value = this.multi ? [] : '';
            this.input.nativeElement.value = '';
        }
        this.initFilters();
    }
    ngOnChanges(changes) {
        if (changes.valueField || changes.labelField) {
            this.updateSelected();
        }
    }
    ngOnDestroy() {
        this.alive = false;
        this.propagateChange = this.propagateTouch = () => false;
    }
    initFilters() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.filters) {
                for (const filter of this.filters) {
                    if (filter.supplier) {
                        this.updateFilter(filter);
                    }
                }
            }
        });
    }
    compare(value, option) {
        return (value != null &&
            (typeof this.valueField === 'string'
                ? value === getValueFromObject(option, this.valueField)
                : typeOf(this.valueField) === 'object'
                    ? Object.keys(this.valueField).find((key) => (value[key] || value) === option[this.valueField[key]]) != null
                    : value === option || deepCompare(value, option, 'id')));
    }
    compareAll(value, option) {
        return value.find((val) => this.compare(val, option)) != null;
    }
    onKeyDown(event) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.blurClearPrevent = event.code === 'Enter';
        // wait a little, to input element value update
        this.timeout = setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.propagateTouch();
            if (this.input.nativeElement.value.length && this.input.nativeElement.value !== this.singleSelectedLabel) {
                if (this.supplier && event.code !== 'Enter') {
                    event.stopPropagation();
                    const sup = this.supplier(this.input.nativeElement.value, SupplierCallType.UPDATE, this.getValue, this.setValue, this.filterValues);
                    if (sup instanceof Observable) {
                        sup.toPromise().then((res) => {
                            if (res && res.options) {
                                this.innerOptions = res.options;
                                this.onFilterChange(null, null);
                                this.populate(event.code === 'Enter');
                            }
                        });
                    }
                    else if (sup && sup.options) {
                        this.innerOptions = sup.options;
                        this.onFilterChange(null, null);
                        this.populate(event.code === 'Enter');
                    }
                    else {
                        this.populate(event.code === 'Enter');
                    }
                }
                else {
                    event.stopPropagation();
                    this.populate(event.code === 'Enter');
                }
            }
            else {
                this.showTooltipToEnter = false;
                this.closed = true;
                this.clickDir.detach();
                this.detect();
            }
        }), 400);
    }
    populate(onEnter) {
        this.filter();
        if (this.filteredOptions.length || this.showCheckbox) {
            this.showTooltipToEnter = false;
            this.showOptionList();
        }
        else if (this.input.nativeElement.value.length) {
            let keepTooltip = false;
            if (onEnter && this.manualInputOn != null && !this.multi) {
                this.value = this.manualInputOn ? { [this.manualInputOn]: this.input.nativeElement.value } : this.input.nativeElement.value;
                this.propagateChange();
                this.input.nativeElement.blur();
            }
            else {
                this.showTooltipToEnter = keepTooltip = true;
                this.clickDir.attach();
            }
            this.hideOptions(keepTooltip);
            if (keepTooltip) {
                this.detect();
            }
        }
        else {
            this.detect();
        }
    }
    updateSelected() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.closed) {
                return;
            }
            if (this.multi && this.valueInner) {
                this.selectedItems = this.valueInner.map((v) => this.everSelectedItems.find((option) => this.compare(v, option))).filter((s) => s);
            }
            else {
                this.selectedItem = this.everSelectedItems.find((option) => this.compare(this.value, option)) || this.selectedFromValue;
                if (this.selectedItem) {
                    if (Array.isArray(this.labelField)) {
                        const filtered = this.labelField.filter((label) => !(label.hidden || label.hiddenAsValue));
                        const values = [];
                        for (const l of filtered) {
                            values.push(l.supplier
                                ? l.supplier(this.selectedItem)
                                : l.supplierAsync
                                    ? yield l.supplierAsync(this.selectedItem).toPromise()
                                    : getValueFromObject(this.selectedItem, l.name));
                        }
                        this.singleSelectedLabel = values.join(' ');
                    }
                    else {
                        this.singleSelectedLabel = this.labelField
                            ? getValueFromObject(this.selectedItem, this.labelField.toString())
                            : this.selectedItem;
                    }
                }
                else {
                    this.singleSelectedLabel = '';
                }
                this.input.nativeElement.value = this.singleSelectedLabel;
            }
        });
    }
    get selectedFromValue() {
        if (this.value != null && this.manualInputOn) {
            const val = typeof this.value === 'object' && this.manualInputOn ? this.value[this.manualInputOn] : this.value;
            if (Array.isArray(this.labelField)) {
                const res = {};
                this.labelField.forEach((label) => (res[label.name] = res[label.name] || val));
                return res;
            }
            else if (typeof this.labelField === 'string') {
                return { [this.labelField]: val };
            }
            else {
                return val;
            }
        }
    }
    onBlur(event) {
        this.hideOptions();
        if (!this.blurClearPrevent && !this.multi) {
            this.updateSelected();
        }
        this.blurClearPrevent = false;
    }
    onOptionSelect(option) {
        this.propagateTouch();
        if (this.multi) {
            if (!Array.isArray(this.valueInner)) {
                this.valueInner = [];
            }
            const val = typeof this.valueField === 'string' ? getValueFromObject(option, this.valueField) : option;
            const idx = this.valueInner.indexOf(val);
            if (idx > -1) {
                this.valueInner.splice(idx, 1);
                this.selectedItems.splice(idx, 1);
                this.everSelectedItems.remove(option, true);
            }
            else {
                this.valueInner.push(val);
                this.selectedItems.push(option);
                this.everSelectedItems.include(option, true);
            }
            this.propagateChange();
            this.input.nativeElement.value = '';
            if (!this.showCheckbox) {
                this.hideOptions();
            }
            else {
                this.detect();
            }
        }
        else {
            this.everSelectedItems.length = 0;
            this.value = typeof this.valueField === 'string' ? getValueFromObject(option, this.valueField) : option;
            this.everSelectedItems.include(option, true);
            this.propagateChange();
            this.hideOptions();
        }
    }
    onFilterChange(key, value, suppressEvent) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.filters) {
                const filter = this.filters.find((item) => item.name === key);
                if (filter) {
                    value =
                        value === NULL_SELECTED_VALUE
                            ? null
                            : filter.valueField
                                ? getValueFromObject(filter.options[value], filter.valueField)
                                : filter.options[value];
                    if (filter.linkTo) {
                        for (const linkKey of filter.linkTo) {
                            const linkFilter = this.filters.find((linkItem) => linkItem.name === linkKey);
                            if (linkFilter && linkFilter.supplier && linkKey !== key) {
                                this.updateFilter(linkFilter, value);
                            }
                        }
                    }
                    if (!filter.transparent) {
                        this.filterValues[key] = value;
                    }
                    else {
                        this.transparentFilterValues[key] = value;
                    }
                }
            }
            if (!suppressEvent) {
                this.globalFilteredOptions = this.filters
                    ? this.options.filter((option) => !Object.keys(this.filterValues).find((filterKey) => {
                        const filterVal = option[filterKey] === undefined ? undefined : this.filterValues[filterKey];
                        return Array.isArray(filterVal)
                            ? filterVal.find((f) => f === option[filterKey]) == null
                            : filterVal != null && option[filterKey] !== filterVal;
                    }))
                    : this.options;
            }
        });
    }
    updateFilter(filter, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const sup = filter.supplier(value);
            if (sup instanceof Observable) {
                sup.toPromise().then((res) => {
                    if (res != null) {
                        const options = res.options;
                        const linkValue = isNestedSupplied(res) ? res.value : res;
                        filter.options = options || filter.options;
                        if (linkValue != null) {
                            this.onFilterChange(filter.name, linkValue, true);
                        }
                        this.detect();
                    }
                });
            }
            else if (sup != null) {
                const options = sup.options;
                const linkValue = isNestedSupplied(sup) ? sup.value : sup;
                filter.options = options || filter.options;
                if (linkValue != null) {
                    this.onFilterChange(filter.name, linkValue, true);
                }
                this.detect();
            }
        });
    }
    onArrowButtonClick() {
        if (this.closed && !this.readonly) {
            this.showCheckbox = true;
            const sup = this.supplier
                ? this.supplier(this.input.nativeElement.value, SupplierCallType.UPDATE, this.getValue, this.setValue, this.filterValues)
                : null;
            if (sup instanceof Observable) {
                sup.toPromise().then((res) => {
                    if (res && res.options) {
                        this.innerOptions = res.options;
                    }
                    this.onFilterChange(null, null).then(() => this.showOptionList());
                });
            }
            else if (sup && sup.options) {
                this.innerOptions = sup.options;
                this.onFilterChange(null, null).then(() => this.showOptionList());
            }
            else {
                this.showOptionList();
            }
        }
    }
    onDeleteClick(option) {
        if (!this.readonly && this.valueInner) {
            this.everSelectedItems.remove(option, true);
            this.selectedItems.remove(option);
            this.valueInner.remove(typeof this.valueField === 'string' ? getValueFromObject(option, this.valueField) : option);
            this.propagateTouch();
            this.propagateChange();
            this.detect();
        }
    }
    clear() {
        if (this.multi) {
            this.valueInner.length = 0;
            this.selectedItems.length = 0;
        }
        else {
            this.valueInner = null;
            this.selectedItem = null;
        }
        this.everSelectedItems.length = 0;
        this.input.nativeElement.value = '';
        this.showTooltipToEnter = false;
        this.propagateTouch();
        this.propagateChange();
        this.detect();
    }
    filter() {
        const labels = Array.isArray(this.labelField) ? this.labelField.filter((l) => !l.skipInFilter) : [];
        this.filteredOptions =
            this.input.nativeElement.value.length > 0 || (this.multi && this.selectedItems.length)
                ? this.globalFilteredOptions.filter((x) => {
                    if (Array.isArray(this.labelField)) {
                        if (!labels.length) {
                            return true;
                        }
                        for (const label of labels) {
                            const l = label.supplier ? label.supplier(x) : getValueFromObject(x, label.name);
                            if (l && l.toString().includes(this.input.nativeElement.value)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return (this.labelField ? getValueFromObject(x, this.labelField.toString()) : x).includes(this.input.nativeElement.value);
                })
                : this.globalFilteredOptions;
    }
    hideOptions(keepTooltip = false) {
        this.showCheckbox = false;
        if (!keepTooltip) {
            this.showTooltipToEnter = false;
            this.clickDir.detach();
        }
        if (this.closed) {
            return;
        }
        this.closed = true;
        if (!this.multi && this.value && this.input.nativeElement.value !== this.value) {
            this.updateSelected();
        }
        if (!keepTooltip) {
            this.detect();
        }
    }
    showOptionList() {
        if (!this.readonly && (this.filteredOptions.length || this.showCheckbox)) {
            this.closed = false;
            this.clickDir.attach();
        }
        this.detect();
    }
    writeValue(value) {
        const val = value == null && this.multi ? [] : value;
        if (this.multi) {
            const currentSelected = [...this.options, ...this.everSelectedItems].filter((option) => this.compareAll(val, option));
            this.everSelectedItems.length = 0;
            this.everSelectedItems.push(...currentSelected);
        }
        else {
            this.everSelectedItems.length = 0;
            const selected = this.options.find((option) => this.compare(val, option));
            if (selected) {
                this.everSelectedItems.push(selected);
            }
        }
        this.value = val;
        this.detect();
    }
    registerOnChange(fn) {
        this.propagateChange = () => {
            fn(this.valueInner);
            this.valueChange.emit(this.valueInner);
        };
    }
    registerOnTouched(fn) {
        this.propagateTouch = fn;
    }
    setDisabledState(isDisabled) {
        this.readonly = isDisabled;
    }
    validate(c) {
        if (!this.alive) {
            return null;
        }
        const valid = {};
        if (this && this.validators) {
            Object.keys(this.validators).forEach((key) => {
                const ret = typeof this.validators[key] === 'function' ? this.validators[key](c) : null;
                if (ret != null ||
                    (key === 'required' &&
                        this.validators[key] &&
                        (this.value == null || (this.multi && (!this.valueInner || !this.valueInner.length)))) ||
                    (key === 'min' && this.multi && (!this.valueInner || this.valueInner.length < this.validators[key])) ||
                    (key === 'max' && this.multi && this.valueInner && this.valueInner.length > this.validators[key])) {
                    valid[key] = ret || true;
                }
            });
        }
        return Object.keys(valid).length ? valid : null;
    }
    detect() {
        if (this.alive) {
            this.cdr.detectChanges();
        }
    }
}
