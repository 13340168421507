import { ApiService } from '@agent-ds/shared/models/api-service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OutlookApiService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    getMessageInfo(messageId) {
        return this.http.get('/api/mail/integration/' + messageId);
    }
}
OutlookApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OutlookApiService_Factory() { return new OutlookApiService(i0.ɵɵinject(i1.HttpClient)); }, token: OutlookApiService, providedIn: "root" });
