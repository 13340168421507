import { downloadResponse, toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ExportApiService {
    constructor(http) {
        this.http = http;
    }
    export(model, templateId, params) {
        return this.http
            .get(`/api/export/${model}/${templateId}`, { responseType: 'blob', observe: 'response', params: params })
            .pipe(tap((res) => downloadResponse(res)));
    }
    exportContracts(params) {
        return this.http
            .get('/api/enterprise/csv/contract/export', {
            responseType: 'blob',
            observe: 'response',
            params: params,
        })
            .pipe(tap((res) => downloadResponse(res)));
    }
    exportSales(salesIds, requestObj) {
        return this.http
            .get('/api/sales/file/csv', {
            responseType: 'blob',
            observe: 'response',
            params: requestObj || { salesId: salesIds },
        })
            .pipe(tap((res) => downloadResponse(res)));
    }
    exportInvoice(salesId) {
        return this.http
            .get(`/api/sales/${salesId}/invoice/export`, {
            responseType: 'blob',
            observe: 'response',
        })
            .pipe(tap((res) => downloadResponse(res)));
    }
    exportProgresses(params) {
        return this.http
            .get('/api/progress/csv', {
            responseType: 'blob',
            observe: 'response',
            params: params,
        })
            .pipe(tap((res) => downloadResponse(res)));
    }
    exportStudentsForSeminar(seminarId) {
        return this.http
            .get(`/api/seminar/${seminarId}/student/excel`, {
            responseType: 'blob',
            observe: 'response',
        })
            .pipe(tap((res) => downloadResponse(res)));
    }
    exportJobsForSeminar(seminarId, jobIds) {
        return this.http
            .get(`/api/seminar/${seminarId}/sheet`, {
            responseType: 'blob',
            observe: 'response',
            params: toAsialRequestParameters({ jobIds: jobIds }),
        })
            .pipe(tap((res) => downloadResponse(res)));
    }
    getFields(model) {
        return this.http.get('/api/export/templates/fields', { params: { model: model } });
    }
    getTemplates(model, sort, order) {
        return this.http.get('/api/export/templates', {
            params: { model: model, sort: sort || 'updatedAt', order: order || 'desc' },
        });
    }
    getTemplate(templateId) {
        return this.http.get(`/api/export/templates/${templateId}`);
    }
    addTemplate(template) {
        return this.http.post('/api/export/templates', template);
    }
    updateTemplate(id, template) {
        return this.http.put(`/api/export/templates/${id}`, template);
    }
    deleteTemplate(templateId) {
        return this.http.delete(`/api/export/templates/${templateId}`);
    }
}
ExportApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExportApiService_Factory() { return new ExportApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ExportApiService, providedIn: "root" });
