import { CACHE } from '@agent-ds/shared/util/cache';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { download, openFile } from '../../util/util';
import { StudentApiService } from './student-api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./student-api.service";
export class FileApiService {
    constructor(http, studentService) {
        this.http = http;
        this.studentService = studentService;
    }
    getFiles(model = 'students', id) {
        return this.http.get(`/api/${model}/${id}/files`);
    }
    uploadFile(model = 'students', id, file, fileName, fileId, enterpriseSendFlag, studentSendFlag) {
        const formData = new FormData();
        formData.append('file', file, fileName || file.name);
        formData.append('enterpriseSendFlag', `${enterpriseSendFlag}`);
        formData.append('studentSendFlag', `${studentSendFlag}`);
        const url = `/api/${model}/${id}/file` + (fileId ? `/${fileId}/replace` : '');
        const obs = fileId ? this.http.put(url, formData) : this.http.post(url, formData);
        return model === 'students' && fileName && fileName.startsWith('face_')
            ? obs.pipe(tap(() => {
                CACHE.clear(`/api/students/${id}/profile-image/`);
                this.studentService.refreshEvent.emit();
            }))
            : obs;
    }
    downloadFile(model = 'students', id, fileId, fileName) {
        this.http.get(`/api/${model}/${id}/file/${fileId}`, { responseType: 'blob' }).subscribe((res) => download(res, fileName));
    }
    openFile(model = 'students', id, fileId, fileName) {
        this.http.get(`/api/${model}/${id}/file/${fileId}`, { responseType: 'blob' }).subscribe((res) => openFile(res, fileName));
    }
    deleteFile(model = 'students', id, fileId) {
        return this.http.delete(`/api/${model}/${id}/file/${fileId}`);
    }
    downloadSecondMatchCsv(from, to) {
        return this.http.get('/api/second-matching/file/csv', {
            responseType: 'blob',
            observe: 'response',
            params: { from: from, to: to },
            headers: {
                'Content-Type': 'application/csv',
                Accept: 'application/csv',
            },
        });
    }
    getReportCsvList() {
        return this.http.get('/api/published-csv');
    }
    downloadReportCsv(id) {
        return this.http.get(`/api/published-csv/${id}`, { responseType: 'blob', observe: 'response' });
    }
    getUnconfirmedFiles(from, size) {
        return this.http.get('/api/students/uploads', {
            params: {
                from: from,
                size: 9999,
            },
        });
    }
    renameStudentUploadedFile(studentId, fileId, fileName) {
        return this.http.put(`/api/students/${studentId}/file/${fileId}/rename`, { name: fileName });
    }
    changeToSendable(studentId, fileId) {
        return this.http.put(`/api/students/${studentId}/file/${fileId}/change-to-sendable`, {});
    }
}
FileApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileApiService_Factory() { return new FileApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StudentApiService)); }, token: FileApiService, providedIn: "root" });
