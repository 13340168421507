import { ApiService } from '@agent-ds/shared/models/api-service';
import { CACHE } from '@agent-ds/shared/util/cache';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { merge, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class StudentApiService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    getList(search) {
        return this.http.get('/api/students/search', { params: search });
    }
    getDetail(id) {
        return this.http.get('/api/students/' + id).pipe(tap((student) => {
            student.userId = student.studentUsers && student.studentUsers.length ? student.studentUsers[0].userId : null;
            // 副担当者のIDを抽出
            if (student.studentSubUsers && student.studentSubUsers.length) {
                student.subUserIds = student.studentSubUsers.map((studentSubUser) => {
                    return studentSubUser.userId;
                });
            }
            else {
                // do nothing
            }
            return student;
        }));
    }
    create(studentData) {
        return this.http.post('/api/students', studentData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    update(id, studentData) {
        return this.http.put('/api/students/' + id, studentData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    bulkUpdate(key, value, studentIds, condition) {
        return this.http.put('/api/students/bulk-update', {
            key: key,
            value: value,
            studentIds: studentIds,
            condition: condition,
        });
    }
    getMemos(id) {
        return this.http.get(`/api/students/${id}/memos`);
    }
    addMemo(id, memo) {
        return this.http.post(`/api/students/${id}/memos`, memo);
    }
    updateMemo(id, memo) {
        return this.http.put(`/api/students/${id}/memos/${memo.id}`, memo);
    }
    deleteMemo(id, memoId) {
        return this.http.delete(`/api/students/${id}/memos/${memoId}`);
    }
    getContacts(id, actionId) {
        return this.http.get(`/api/students/${id}/contact-histories`, actionId ? { params: { action: `${actionId}` } } : undefined);
    }
    addContact(id, request) {
        return this.http.post(`/api/students/${id}/contact-histories`, request);
    }
    updateContact(id, request) {
        return this.http.put(`/api/students/${id}/contact-histories/${request.id}`, request);
    }
    deleteContact(id, contactId) {
        return this.http.delete(`/api/students/${id}/contact-histories/${contactId}`);
    }
    getJobs(id) {
        return this.http.get(`/api/students/${id}/job-offers`);
    }
    getJobFiles(id) {
        return this.http.get(`/api/students/${id}/job-files`);
    }
    downloadJob(id, attachmentId) {
        this.http
            .get(`/api/students/${id}/job-offer/${attachmentId}`, { responseType: 'blob' })
            .subscribe((res) => window.open(window.URL.createObjectURL(res)));
    }
    downloadJobFile(id, fileId) {
        this.http
            .get(`/api/students/${id}/job-file/${fileId}`, {
            responseType: 'blob',
            observe: 'response',
            headers: { 'Content-Type': 'application/json' },
        })
            .subscribe((res) => downloadResponse(res));
    }
    addJob(id, file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.http.post(`/api/students/${id}/job-offer`, formData).subscribe();
    }
    deleteJob(id, attachmentId) {
        this.http.delete(`/api/students/${id}/job-offer/${attachmentId}`);
    }
    getProfileImage(id, size) {
        const url = `/api/students/${id}/profile-image/${size}`;
        CACHE.set(url, null, 300000);
        return this.http.get(url, { responseType: 'blob' }).pipe(map((res) => window.URL.createObjectURL(res)), catchError((error) => of('assets/images/profile.png')));
    }
    getProfileImages(ids, size) {
        const observables = [];
        for (const id of ids) {
            observables.push(this.getProfileImage(id, size));
        }
        return merge(observables);
    }
    addNote(studentId, note) {
        return this.http.post(`/api/students/${studentId}/note`, note);
    }
    updateNote(studentId, note) {
        return this.http.put(`/api/students/${studentId}/note/${note.id}`, note);
    }
    deleteNote(studentId, noteId) {
        return this.http.delete(`/api/students/${studentId}/note/${noteId}`);
    }
    getSuggestions(keyword, ids) {
        return keyword || (ids && ids.length)
            ? this.http.get(`/api/students/suggest`, { params: { name: keyword || undefined, id: ids } })
            : of([]);
    }
    getSuggestionsForIntakeCa(keyword, ids) {
        return keyword || (ids && ids.length)
            ? this.http.get(`/api/students/suggestForIntakeCa`, {
                params: { name: keyword || undefined, id: ids },
            })
            : of([]);
    }
    downloadImportExampleFile() {
        this.http.get('/api/students-import/header', { responseType: 'blob', observe: 'response' }).subscribe((res) => downloadResponse(res));
    }
    uploadStudentImportFile(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post('/api/students-import/file', formData);
    }
    deleteStudentImportFiles() {
        return this.http.delete('/api/students-import/file');
    }
    getImportFiles() {
        return this.http.get('/api/students-import/files');
    }
    getImportFile(fileId, registrationRoute = null) {
        return this.http.get(`/api/students-import/file/${fileId}`, {
            params: registrationRoute ? { registrationRoute: registrationRoute } : null,
        });
    }
    getImportErrorDetail(studentImportErrorId) {
        return this.http.get(`/api/students-import/error/${studentImportErrorId}`);
    }
    getImportErrorDiff(studentImportErrorId, studentId) {
        return this.http.get(`/api/students-import/error/${studentImportErrorId}/diff/${studentId}`);
    }
    addImportError(studentImportErrorId, studentReimportRequest = null) {
        return this.http.post(`/api/students-import/error/${studentImportErrorId}`, studentReimportRequest);
    }
    updateImportError(studentImportErrorId, studentId, studentUpdateRequest) {
        return this.http.put(`/api/students-import/error/${studentImportErrorId}/update/${studentId}`, studentUpdateRequest);
    }
    deleteImportError(importErrorIds) {
        const request = { importErrorIds: importErrorIds };
        return this.http.request('delete', '/api/students-import/error', { body: request });
    }
}
StudentApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentApiService_Factory() { return new StudentApiService(i0.ɵɵinject(i1.HttpClient)); }, token: StudentApiService, providedIn: "root" });
