import { ApiService } from '@agent-ds/shared/models/api-service';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CompanyApiService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    getBllingAddressCode(billignBikeCb) {
        // const ans = this.http.get<string>('/api/enterprise/search/billing-address-code?bllingAddressCode='+bllingAddressCode);
        const ans = this.http.get('/api/enterprise/search/billing-address-code', { params: { billignBikeCb: billignBikeCb } });
        return ans;
    }
    getList(search) {
        return this.http.get('/api/enterprise/search', { params: (search ? search : {}) });
    }
    getSuggestions(keyword, ids) {
        return keyword || (ids && ids.length)
            ? this.http.get(`/api/enterprise/suggest`, {
                params: { name: keyword || undefined, id: ids },
            })
            : of([]);
    }
    getDetail(id) {
        return this.http.get('/api/enterprise/' + id);
    }
    create(companyData) {
        return this.http.post('/api/enterprise', companyData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    update(id, companyData) {
        return this.http.put('/api/enterprise/' + id, companyData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    getDepartments(enterpriseId) {
        return this.http.get(`/api/enterprise/${enterpriseId}/departments`);
    }
    addDepartment(enterpriseId, request) {
        return this.http.post(`/api/enterprise/${enterpriseId}/department`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    updateDepartment(enterpriseId, id, request) {
        return this.http.put(`/api/enterprise/${enterpriseId}/department/${id}`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    addBillingAddress(enterpriseId, request) {
        return this.http.post(`/api/enterprise/${enterpriseId}/billing-address`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    updateBillingAddress(enterpriseId, id, request) {
        return this.http.put(`/api/enterprise/${enterpriseId}/billing-address/${id}`, request, {
            headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' },
        });
    }
    getContracts(id) {
        return this.http.get(`/api/enterprise/${id}/contracts`);
    }
    addContract(enterpriseId, request) {
        return this.http.post(`/api/enterprise/${enterpriseId}/contract`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    updateContract(enterpriseId, id, request) {
        return this.http.put(`/api/enterprise/${enterpriseId}/contract/${id}`, request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    getContacts(id, actionId) {
        return this.http.get(`/api/enterprise/${id}/contact-histories`, actionId ? { params: { action: `${actionId}` } } : undefined);
    }
    addContact(id, request) {
        return this.http.post(`/api/enterprise/${id}/contact-history`, request);
    }
    updateContact(id, request) {
        return this.http.put(`/api/enterprise/${id}/contact-history/${request.id}`, request);
    }
    deleteContact(id, contactId) {
        return this.http.delete(`/api/enterprise/${id}/contact-history/${contactId}`);
    }
    getMemos(id) {
        return this.http.get(`/api/enterprise/${id}/inner-info`);
    }
    addMemo(id, info) {
        return this.http.post(`/api/enterprise/${id}/inner-info`, info);
    }
    updateMemo(id, info) {
        return this.http.put(`/api/enterprise/${id}/inner-info/${info.id}`, info);
    }
    deleteMemo(id, infoId) {
        return this.http.delete(`/api/enterprise/${id}/inner-info/${infoId}`);
    }
    downloadImportExampleFile() {
        this.http.get('/api/enterprise/csv/header', { responseType: 'blob', observe: 'response' }).subscribe((res) => downloadResponse(res));
    }
    uploadCompanyImportFile(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post('/api/enterprise/csv/import', formData);
    }
    downloadEnterpriseArchiveFile(id, params) {
        this.http
            .get(`/api/mail/enterprise/${id}/archived-files`, { params: params, responseType: 'blob', observe: 'response' })
            .subscribe((res) => downloadResponse(res));
    }
}
CompanyApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyApiService_Factory() { return new CompanyApiService(i0.ɵɵinject(i1.HttpClient)); }, token: CompanyApiService, providedIn: "root" });
