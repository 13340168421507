import { CACHE } from '@agent-ds/shared/util/cache';
import { HttpClient } from '@angular/common/http';
import { of, ReplaySubject } from 'rxjs';
import { first, shareReplay, tap } from 'rxjs/operators';
import { AuthService, REPORT_SUCCESS_HEADER } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth.service";
export class MasterApiService {
    constructor(http, authService) {
        this.http = http;
        this.authService = authService;
        this.languages = new ReplaySubject(1);
        this.flattenedIndustryTypes = new ReplaySubject(1);
        this.flattenedJobTypes = new ReplaySubject(1);
        this.authService.isLogined().subscribe((logined) => {
            if (logined) {
                this.branches = this.certificates = this.highSchools = null;
                this.industryTypes = this.jobTypes = this.languageCerts = this.schools = this.skills = this.teams = null;
            }
        });
    }
    getBranches() {
        if (!this.branches) {
            this.branches = this.http.get('/api/master/branches').pipe(shareReplay(1));
        }
        return this.branches.pipe(first());
    }
    getCertificates() {
        if (!this.certificates) {
            this.certificates = this.http.get('/api/master/certificates').pipe(shareReplay(1));
        }
        return this.certificates.pipe(first());
    }
    getHighSchools() {
        if (!this.highSchools) {
            this.highSchools = this.http.get('/api/master/high-schools').pipe(shareReplay(1));
        }
        return this.highSchools.pipe(first());
    }
    getIndustryTypes() {
        if (!this.industryTypes) {
            this.industryTypes = this.http.get('/api/master/industry-types/relations').pipe(tap((res) => this.flattenedIndustryTypes.next(res.flatten((subType, level) => (subType['_selectLevel'] = level), 'industryTypes'))), shareReplay(1));
        }
        return this.industryTypes.pipe(first());
    }
    getFlattenedIndustryTypes() {
        return this.flattenedIndustryTypes.pipe(first());
    }
    getJobTypes() {
        if (!this.jobTypes) {
            this.jobTypes = this.http.get('/api/master/job-types/relations').pipe(tap((res) => this.flattenedJobTypes.next(res.flatten((subType, level) => (subType['_selectLevel'] = level), 'jobTypes'))), shareReplay(1));
        }
        return this.jobTypes.pipe(first());
    }
    getFlattenedJobTypes() {
        return this.flattenedJobTypes.pipe(first());
    }
    getLanguageCerts() {
        if (!this.languageCerts) {
            this.languageCerts = this.http.get('/api/master/language-certificates').pipe(shareReplay(1));
            this.languageCerts.subscribe((res) => this.languages.next(res.map((cert) => cert.language).removeSame('id')), () => this.languages.next([]));
        }
        return this.languageCerts.pipe(first());
    }
    getLanguages() {
        if (!this.languageCerts) {
            this.getLanguageCerts();
        }
        return this.languages.pipe(first());
    }
    searchStations(keyword) {
        return this.http.get('/api/master/schools/relations?keyword=' + keyword);
    }
    getSchools() {
        if (!this.schools) {
            this.schools = this.http.get('/api/master/schools/relations').pipe(shareReplay(1));
        }
        return this.schools.pipe(first());
    }
    suggestSchools(keyword) {
        return keyword ? this.http.get('/api/master/schools/suggest', { params: { keyword: keyword } }) : of([]);
    }
    getSkills() {
        if (!this.skills) {
            this.skills = this.http.get('/api/master/skills/relations').pipe(shareReplay(1));
        }
        return this.skills.pipe(first());
    }
    getTeams() {
        if (!this.teams) {
            this.teams = this.http.get('/api/master/teams').pipe(shareReplay(1));
        }
        return this.teams.pipe(first());
    }
    addTeam(team) {
        return this.http.post('/api/master/teams', team).pipe(tap(() => (this.teams = null)));
    }
    updateTeam(id, team) {
        return this.http.put(`/api/master/teams/${id}`, team).pipe(tap(() => (this.teams = null)));
    }
    getAddressByZip(zip) {
        if (zip) {
            CACHE.set('/api/address/search/zip', null, 300000);
            return this.http.get('/api/address/search/zip', { params: { zipCode: zip } });
        }
        else {
            return of({ results: [] });
        }
    }
    getSystemConfigs() {
        return this.http.get('/api/master/system-config');
    }
    updateSystemConfig(id, requestObj) {
        return this.http.put(`/api/master/system-config/${id}`, requestObj, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
}
MasterApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MasterApiService_Factory() { return new MasterApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: MasterApiService, providedIn: "root" });
