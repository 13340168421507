import { map } from 'rxjs/operators';
import { PopupControlComponent } from '../components/popup-control/popup-control.component';
import { AuthService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
export class ZoneGuard {
    constructor(authService) {
        this.authService = authService;
    }
    canActivate(route, state) {
        return this.authService.isLogined().pipe(map(() => {
            if (this.authService.authInfo.zone === 'zone3') {
                return true;
            }
            else {
                PopupControlComponent.instance.open({
                    title: null,
                    content: 'ZONE3専用です。',
                    confirmText: 'OK',
                    confirmCallback: () => false,
                });
                return false;
            }
        }));
    }
    canActivateChild(childRoute, state) {
        return this.canActivate(childRoute, state);
    }
}
ZoneGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ZoneGuard_Factory() { return new ZoneGuard(i0.ɵɵinject(i1.AuthService)); }, token: ZoneGuard, providedIn: "root" });
