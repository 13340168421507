import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CounselingApiService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    getSeminarsForStudent(studentId) {
        return this.http.get(`/api/seminar/student/${studentId}`);
    }
    deleteSeminarForStudent(seminarId, studentId) {
        return this.http.delete(`/api/seminar/student/${studentId}/seminar/${seminarId}`);
    }
    addSeminarForStudent(studentId, seminarId) {
        return this.http.post(`/api/seminar/student/${studentId}`, { seminarId: seminarId });
    }
    searchSeminarsForStudent(studentId, params) {
        return this.http.get(`/api/seminar/student/${studentId}/search`, {
            params: toAsialRequestParameters(params),
        });
    }
    getSeminarJobsForStudent(studentId, seminarId) {
        return this.http.get(`/api/seminar/student/${studentId}/seminar/${seminarId}/job`);
    }
    addSeminarJobsForStudent(studentId, seminarId, jobId) {
        return this.http.post(`/api/seminar/student/${studentId}/seminar/${seminarId}/job`, { jobId: jobId });
    }
    deleteSeminarJobsForStudent(studentId, seminarId, jobId) {
        return this.http.delete(`/api/seminar/student/${studentId}/seminar/${seminarId}/job/${jobId}`);
    }
    updateSeminarJobApplicationsForStudent(studentId, seminarId, requestObj) {
        return this.http.put(`/api/seminar/student/${studentId}/seminar/${seminarId}/application`, requestObj);
    }
    getSeminars(area, startDate, endDate) {
        return this.http.get('/api/seminar', {
            params: {
                area: area,
                startDate: new Date(startDate).toAsialDateString(true),
                endDate: new Date(endDate).toAsialDateString(true),
            },
        });
    }
    addSeminar(requestObj) {
        return this.http.post('/api/seminar', requestObj);
    }
    deleteSeminar(seminarId) {
        return this.http.delete(`/api/seminar/${seminarId}`);
    }
    deleteSeminars(seminarIds) {
        return this.http.request('delete', '/api/seminar', {
            params: toAsialRequestParameters({ seminarIds: seminarIds }),
        });
    }
    getSeminarMasterJobs(areaId) {
        return this.http.get(`/api/seminar/job-master/${areaId}`);
    }
    addSeminarMasterJob(areaId, jobId) {
        return this.http.post(`/api/seminar/job-master/${areaId}`, { jobId: jobId });
    }
    deleteSeminarMasterJob(areaId, jobId) {
        return this.http.delete(`/api/seminar/job-master/${areaId}/${jobId}`);
    }
    deleteSeminarMasterJobs(areaId, jobIds) {
        return this.http.request('delete', `/api/seminar/job-master/${areaId}`, {
            params: toAsialRequestParameters({ jobIds: jobIds }),
        });
    }
    getSeminarJobs(seminarId) {
        return this.http.get(`/api/seminar/${seminarId}/job`);
    }
    addSeminarJob(seminarId, jobId) {
        return this.http.post(`/api/seminar/${seminarId}/job`, { jobId: jobId });
    }
    deleteSeminarJob(seminarId, jobId) {
        return this.http.delete(`/api/seminar/${seminarId}/job/${jobId}`);
    }
    deleteSeminarJobs(seminarId, jobIds) {
        return this.http.request('delete', `/api/seminar/${seminarId}/job`, {
            params: toAsialRequestParameters({ jobIds: jobIds }),
        });
    }
    getStudentsForSeminar(seminarId) {
        return this.http.get(`/api/seminar/${seminarId}/student`);
    }
    addStudentsThanksMailForSeminar(seminarId, requestObj) {
        return this.http.post(`/api/seminar/${seminarId}/student/thanks-mail`, requestObj);
    }
}
CounselingApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CounselingApiService_Factory() { return new CounselingApiService(i0.ɵɵinject(i1.HttpClient)); }, token: CounselingApiService, providedIn: "root" });
