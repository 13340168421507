import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const DASHBOARD_API_URL = '/api/dashboard';
export class DashboardApiService {
    constructor(http) {
        this.http = http;
        this.refreshEvent = new EventEmitter();
    }
    getTeamKPI(teamId, dashboardType) {
        return this.http.get(`${DASHBOARD_API_URL}/team/${teamId}/kpi/${dashboardType}`);
    }
    getKPI(dashboardType) {
        return this.http.get(`${DASHBOARD_API_URL}/kpi/${dashboardType}`);
    }
    getProgress(dashboardType, year) {
        return this.http.get(`${DASHBOARD_API_URL}/progress/${dashboardType}/${year}`);
    }
    getTeamProgress(teamId, dashboardType, year) {
        return this.http.get(`${DASHBOARD_API_URL}/team/${teamId}/progress/${dashboardType}/${year}`);
    }
    getProgressWithNextYear(dashboardType, year) {
        return this.http.get(`${DASHBOARD_API_URL}/progress/${dashboardType}/${year}/with-next-year`);
    }
    getTeamProgressWithNextYear(teamId, dashboardType, year) {
        return this.http.get(`${DASHBOARD_API_URL}/team/${teamId}/progress/${dashboardType}/${year}/with-next-year`);
    }
    getEnterprises() {
        return this.http.get(`${DASHBOARD_API_URL}/enterprises`);
    }
    getTeamEnterprises(teamId) {
        return this.http.get(`${DASHBOARD_API_URL}/team/${teamId}/enterprises`);
    }
    getEnterprisesOfAssistant() {
        return this.http.get(`${DASHBOARD_API_URL}/enterprises/ra-assistant`);
    }
    getStudents() {
        return this.http.get(`${DASHBOARD_API_URL}/students`);
    }
    getTeamStudents(teamId) {
        return this.http.get(`${DASHBOARD_API_URL}/team/${teamId}/students`);
    }
    updateKpi(kpi) {
        return this.http.put(`${DASHBOARD_API_URL}/kpi/target`, kpi, { observe: 'response' });
    }
    updateTeamKpi(teamId, kpi) {
        return this.http.put(`${DASHBOARD_API_URL}/team/${teamId}/kpi/target`, kpi, { observe: 'response' });
    }
}
DashboardApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardApiService_Factory() { return new DashboardApiService(i0.ɵɵinject(i1.HttpClient)); }, token: DashboardApiService, providedIn: "root" });
