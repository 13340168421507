import * as i0 from "@angular/core";
/**
 * 永続ストレージ
 */
export class PermanentStorageService {
    constructor() {
        /**
         * キー値のプレフィクス
         */
        this.prefix = 'agent-ds';
    }
    /**
     * キー値を取得する
     */
    key(key) {
        return `${this.prefix}:${key}`;
    }
    /**
     * 取得する
     */
    get(key) {
        const data = localStorage.getItem(this.key(key));
        if (!data) {
            return null;
        }
        try {
            return JSON.parse(data);
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
    /**
     * 保存する
     */
    set(key, value) {
        return localStorage.setItem(this.key(key), JSON.stringify(value));
    }
    /**
     * 削除する
     */
    remove(key) {
        return localStorage.removeItem(this.key(key));
    }
}
PermanentStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermanentStorageService_Factory() { return new PermanentStorageService(); }, token: PermanentStorageService, providedIn: "root" });
