import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MatchingApiService {
    constructor(http) {
        this.http = http;
        this.refreshEvent = new EventEmitter();
    }
    getMatchingsForStudent(studentId) {
        return this.http.get(`/api/matching/student/${studentId}`);
    }
    saveMatchingForStudent(matching) {
        return this.http.post('/api/matching/student', matching);
    }
    deleteStudentMatching(studentMatchingId) {
        return this.http.delete(`/api/matching/student/${studentMatchingId}`);
    }
    getMatchingsForJob(jobId) {
        return this.http.get(`/api/matching/job/${jobId}`);
    }
    saveMatchingForJob(matching) {
        return this.http.post('/api/matching/job', matching);
    }
    deleteJobMatching(jobMatchingId) {
        return this.http.delete(`/api/matching/job/${jobMatchingId}`);
    }
}
MatchingApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MatchingApiService_Factory() { return new MatchingApiService(i0.ɵɵinject(i1.HttpClient)); }, token: MatchingApiService, providedIn: "root" });
