import { HelperBase } from './helper-base';
export class BillingExtraInformationHelper extends HelperBase {
    constructor() {
        super(...arguments);
        this.endOfMonthActionProp = {
            subscribers: [],
            sameBlock: true,
            actions: [
                {
                    title: '当月末',
                    type: 'RUNNABLE',
                    runnable: () => new Date().toEndOfMonth(),
                },
            ],
        };
        this.endOfNextMonthActionProp = {
            subscribers: [],
            sameBlock: true,
            overwrite: true,
            actions: [
                {
                    title: '来月末',
                    type: 'RUNNABLE',
                    runnable: () => new Date().addMonths(1).toEndOfMonth(),
                },
            ],
        };
        this.editable = { rows: [] };
        this.matrix = [];
    }
    updateFieldDefinitions() {
        this.editable = this.getTemplate(true);
        this.matrix = [
            // CS: undefined    CS: 1 (not applied) CS: 2 (requested) CS: 3 (approved)
            [null, null, null, null],
            [null, null, null, null],
            [this.editable, this.editable, this.editable, this.editable],
            [null, null, null, null],
        ];
    }
    getMetadata(approvalStatus, cancellationStaus) {
        return [this.matrix[approvalStatus][cancellationStaus]];
    }
    getTemplate(editable) {
        const res = {
            // Billing information
            title: '請求情報',
            class: 'form__group--golden-title-border',
            rows: [
                ...this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'invoiceConfirmation'), null, 'sales.dynamicData'),
                ...this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'invoiceDate'), this.endOfMonthActionProp, 'sales.dynamicData'),
                ...this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'transferDate'), this.endOfNextMonthActionProp, 'sales.dynamicData'),
                ...this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'transferConfirmation'), null, 'sales.dynamicData'),
                ...this.getDynamicRowOrLabel(editable, this.dynamicService.getDefinition('sales', 'billingLinkageStatus'), null, 'sales.dynamicData'),
                ...this.getDynamicRowOrLabel(false, Object.assign({}, this.dynamicService.getDefinition('sales', 'billingLinkageDate'), { validationStyle: { required: false } }), null, 'sales.dynamicData', 'half', (val) => val),
            ],
        };
        this.setupAction(this.endOfMonthActionProp);
        this.setupAction(this.endOfNextMonthActionProp);
        return res;
    }
}
