import { HttpClient } from '@angular/common/http';
import { forkJoin, ReplaySubject } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { PermanentStorageService } from './permanent-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./permanent-storage.service";
const AUTH_SERVICE_KEY_LOGINED = 'auth-logined';
export const REPORT_SUCCESS_HEADER = 'X-REPORT-SUCCESS';
export const HIDE_ERROR_MESSAGE_HEADER = 'X-HIDE-ERROR-MESSAGE-HEADER';
export const HIDE_ERROR_DIALOG_HEADER = 'X-HIDE-ERROR-DIALOG-HEADER';
export class AuthService {
    /**
     * コンストラクタ
     */
    constructor(http, storage) {
        this.http = http;
        this.storage = storage;
        this.logined = new ReplaySubject(1);
        if (!this.hasToken()) {
            this.logout();
        }
    }
    get loginUser() {
        return this.loginUserRef;
    }
    get authInfo() {
        return this.authInfoRef;
    }
    /**
     * ログイン済みか
     */
    isLogined() {
        if (this.hasToken()) {
            if (!this.loginUserRef) {
                if (!this.loginUserGetObservable) {
                    this.loginUserGetObservable = this.user.pipe(tap(() => {
                        this.logined.next(true);
                        this.loginUserGetObservable = null;
                    }));
                    this.loginUserGetObservable.subscribe();
                }
            }
        }
        return this.logined;
    }
    /**
     * ログインする
     */
    login(credentials) {
        return this.http
            .post('/api/auth/login', credentials, {
            observe: 'response',
        })
            .pipe(concatMap((resp) => {
            this.loginUserRef = resp.body;
            this.storage.set(AUTH_SERVICE_KEY_LOGINED, true);
            return this.http.get('/api/auth/info').pipe(map((info) => {
                this.authInfoRef = info;
                this.logined.next(true);
                return resp;
            }));
        }));
    }
    /**
     * ログアウトする
     */
    logout() {
        if (this.hasToken()) {
            this.http.post('/api/auth/logout', {}).subscribe();
            this.storage.remove(AUTH_SERVICE_KEY_LOGINED);
            this.loginUserRef = null;
        }
        this.logined.next(false);
    }
    password(oldPassword, newPassword) {
        return this.http.put('/api/auth/password', { oldPassword: oldPassword, newPassword: newPassword });
    }
    get user() {
        return forkJoin([this.http.get('/api/auth/info'), this.http.get('/api/auth/user')]).pipe(map(([info, user]) => {
            this.authInfoRef = info;
            this.loginUserRef = user;
            return user;
        }));
    }
    /**
     * トークンを保持しているか
     */
    hasToken() {
        return this.storage.get(AUTH_SERVICE_KEY_LOGINED);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PermanentStorageService)); }, token: AuthService, providedIn: "root" });
