import * as i0 from "@angular/core";
export class ToasterService {
    constructor() {
        this.toastRefs = [];
        this.TIMEOUT = 10000;
        this.timeouts = {};
    }
    get toasts() {
        return this.toastRefs;
    }
    addToast(toast) {
        this.removeToast(toast.key);
        this.toastRefs.push(toast);
        this.timeouts[toast.key] = setTimeout(() => this.removeToast(toast.key), this.TIMEOUT);
    }
    removeToast(key) {
        const existingIndex = this.toastRefs.findIndex((t) => t.key === key);
        if (existingIndex > -1) {
            clearTimeout(this.timeouts[key]);
            this.toastRefs.splice(existingIndex, 1);
        }
    }
}
ToasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToasterService_Factory() { return new ToasterService(); }, token: ToasterService, providedIn: "root" });
