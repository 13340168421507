import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { MatchingCreateDialogComponent } from '@agent-ds/matching/components/matching-create-dialog/matching-create-dialog.component';
import { ProgressCreateDialogComponent } from '@agent-ds/progress/components';
import { ConfirmDialogComponent } from '@agent-ds/shared/components/confirm-dialog/confirm-dialog.component';
import { DetailPage } from '@agent-ds/shared/models';
import { DialogService, StudentApiService } from '@agent-ds/shared/services';
import { MatchingApiService } from '@agent-ds/shared/services/api/matching-api.service';
import { deepClone } from '@agent-ds/shared/util/util';
import { AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
export class JobStudentMatchingPageComponent extends DetailPage {
    constructor(matchingApi, dialog, router, activeRoute, service) {
        super(router, service, activeRoute);
        this.matchingApi = matchingApi;
        this.dialog = dialog;
        this.router = router;
        this.activeRoute = activeRoute;
        this.service = service;
        this.studentSearchObj = { keyword: '' };
        this.matchings = [];
        this.listConfiguration = { body: { checkbox: true, hideCreateButton: true, hideBatchUpdateButton: true } };
        this.sideActions = [{ phases: ['100%'] }];
        this.tabs = {};
        this.urlTag = 'matching/job-student';
        this.refreshSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }
    ngOnInit() { }
    ngAfterViewInit() {
        this.jobDetails.jobDetailLoaded.subscribe((job) => {
            this.job = job;
            // マッチング条件で検索項目のデフォルト値を設定する
            this.studentSearchObj = Object.assign({ universityLevel: job.dynamicData.universityLevel ? [...job.dynamicData.universityLevel] : [], 
                // ['男性', '女性', 'その他'] から ['男性', '女性', '回答しない'] への変換
                gender: (() => {
                    if (job.dynamicData.gender) {
                        return [...job.dynamicData.gender].map((value) => {
                            return value === 'その他' ? '回答しない' : value;
                        });
                    }
                    else {
                        return [];
                    }
                })(), academicFieldType: job.dynamicData.academicFieldType ? [...job.dynamicData.academicFieldType] : [], supportYear: job.dynamicData.recruitYear ? [job.dynamicData.recruitYear] : [], 
                // lastAcademicRecord: job.dynamicData.lastAcademicRecord ? [...job.dynamicData.lastAcademicRecord] : [],
                registrationStatus: ['本登録'], communicationSkill: job.dynamicData.communicationSkill, impressionAndCharm: job.dynamicData.impressionAndCharm }, this.studentSearchObj);
            this.loadMatchings(job.id);
            this.updateUrl();
        });
    }
    fill(onRefresh) {
        if (this.referenceId) {
            this.showList = false;
            this.studentSearchObj = {};
            this.jobDetails.referenceId = this.referenceId;
            this.jobDetails.open();
        }
    }
    loadMatchings(jobId, selectedId) {
        this.matchingApi.getMatchingsForJob(jobId).subscribe((matchings) => {
            this.matchings = matchings;
            this.selectedMatching = this.matchings.find((m) => m.id === selectedId);
        });
    }
    onFilterChanged(changes) {
        if (this.selectedMatching) {
            this.studentSearchObj = Object.assign({}, deepClone(this.studentSearchObj), changes);
            this.selectedMatching = undefined;
        }
        else {
            this.studentSearchObj = Object.assign({}, this.studentSearchObj, changes);
        }
    }
    matchingSelected(selected, matching) {
        this.selectedMatching = matching;
        this.studentSearchObj = this.selectedMatching && selected ? deepClone(this.selectedMatching.matchingParameter) : { keyword: '' };
    }
    saveMatching() {
        this.dialog.open(MatchingCreateDialogComponent, null, (dialogModel) => {
            if (dialogModel) {
                const matching = {
                    name: dialogModel.name,
                    jobId: this.job.id,
                    matchingParameter: this.studentSearchObj,
                };
                return this.matchingApi.saveMatchingForJob(matching).pipe(tap((res) => this.loadMatchings(this.job.id, res.id)));
            }
        });
    }
    deleteMatching(matching) {
        const config = {
            title: '求人検索マッチング条件の削除',
            messages: ['マッチング条件を削除してもよろしいですか？'],
            style: {
                height: '245px',
                width: '510px',
            },
            buttons: {
                no: 'キャンセル',
                yes: 'OK',
            },
        };
        this.dialog.open(ConfirmDialogComponent, config, (confirmed) => {
            if (confirmed) {
                return this.matchingApi.deleteJobMatching(matching.id).pipe(tap(() => {
                    this.loadMatchings(this.job.id);
                }));
            }
        });
    }
    doStudentSearch(params) {
        this.showList = true;
        setTimeout(() => (this.studentSearchObj = Object.assign({}, this.studentSearchObj, params)));
    }
    createProgress() {
        this.dialogRef = this.dialog.open(ProgressCreateDialogComponent, {
            data: {
                jobList: [
                    Object.assign({ frontJobId: this.job.frontId }, this.job),
                ],
                studentList: this.studentList.checkedItems,
                matchingMode: true,
            },
        });
    }
    toSearch() {
        DetailPage.closeAll([JobStudentMatchingPageComponent]);
        this.showList = false;
    }
}
