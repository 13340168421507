import { Router } from '@angular/router';
import { AuthService } from '@agent-ds/shared/services';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
export var USAGE_MODE;
(function (USAGE_MODE) {
    USAGE_MODE[USAGE_MODE["APP"] = 0] = "APP";
    USAGE_MODE[USAGE_MODE["OUTLOOK"] = 1] = "OUTLOOK";
})(USAGE_MODE || (USAGE_MODE = {}));
export class AuthGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
        this.authService.isLogined().subscribe((logined) => {
            if (!logined) {
                AuthGuard.REQUESTED_URL = AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK ? 'mailerIntegration-mail' : this.router.url;
                this.router.navigateByUrl((AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK ? 'mailerIntegration-' : '') + 'login');
            }
        });
    }
    static get REQUESTED_URL() {
        return AuthGuard.REQUESTED_PATH;
    }
    static set REQUESTED_URL(url) {
        AuthGuard.REQUESTED_PATH = !url || url.endsWith('login') ? '' : url;
    }
    canActivate(route, state) {
        const isOutlook = state.url.includes('mailerIntegration');
        if ((AuthGuard.CURRENT_USAGE === USAGE_MODE.APP && isOutlook) || (AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK && !isOutlook)) {
            return false;
        }
        return this.authService.isLogined().pipe(map((allowed) => {
            const isLogin = state.url.match(new RegExp(/^.*login#?\/?$/));
            if (!allowed) {
                if (isLogin) {
                    return true;
                }
                AuthGuard.REQUESTED_URL = state.url;
                this.router.navigateByUrl((AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK ? 'mailerIntegration-' : '') + 'login');
                return false;
            }
            else if (isLogin) {
                this.router.navigateByUrl(AuthGuard.CURRENT_USAGE === USAGE_MODE.OUTLOOK ? 'mailerIntegration-mail' : '');
                return false;
            }
            return allowed;
        }));
    }
}
AuthGuard.CURRENT_USAGE = window.location.href.includes('mailerIntegration')
    ? USAGE_MODE.OUTLOOK
    : USAGE_MODE.APP;
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
