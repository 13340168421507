import { HelperBase } from './helper-base';
export class RemarkHelper extends HelperBase {
    constructor() {
        super(...arguments);
        this.metadata = { rows: [] };
    }
    updateFieldDefinitions() {
        this.metadata = {
            // Remarks
            title: '備考',
            class: 'form__group--golden-title-border',
            rows: [...this.dynamicService.getFormRows(this.dynamicService.getDefinition('sales', 'remarks'), 'sales.dynamicData', 'fill')],
        };
    }
    getMetadata(approvalStatus, cancellationStaus) {
        return [this.metadata];
    }
}
