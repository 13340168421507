import { HttpClient } from '@angular/common/http';
import { first, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth.service";
export class UserApiService {
    constructor(http, authService) {
        this.http = http;
        this.authService = authService;
        this.authService.isLogined().subscribe((logined) => {
            if (logined) {
                this.users = null;
            }
        });
    }
    get(id) {
        return this.http.get('/api/master/users/' + id);
    }
    getAll() {
        if (!this.users) {
            this.users = this.http.get('/api/master/users').pipe(shareReplay(1));
        }
        return this.users.pipe(first());
    }
    add(user) {
        return this.http.post('/api/master/users', user).pipe(tap(() => (this.users = null)));
    }
    update(id, user) {
        return this.http.put('/api/master/users/' + id, user).pipe(tap(() => (this.users = null)));
    }
    delete(id) {
        this.http.delete('/api/master/users/' + id).pipe(tap(() => (this.users = null)));
    }
}
UserApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserApiService_Factory() { return new UserApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: UserApiService, providedIn: "root" });
