import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injector, Type } from '@angular/core';
import { DialogConfig } from '../components/dialog/dialog-config';
import { DialogInjector } from '../components/dialog/dialog-injector';
import { DialogRef } from '../components/dialog/dialog-ref';
import { DialogComponent } from '../components/dialog/dialog.component';
import * as i0 from "@angular/core";
export class DialogService {
    constructor(componentFactoryResolver, appRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
    }
    open(componentType, config, beforeClose) {
        if (!config) {
            config = { data: {} };
        }
        const dialogRef = this.appendDialogComponentToBody(config, beforeClose);
        this.dialogComponentRef.instance.childComponentType = componentType;
        return dialogRef;
    }
    appendDialogComponentToBody(config, beforeClose) {
        const map = new WeakMap();
        map.set(DialogConfig, config);
        const dialogRef = new DialogRef(beforeClose);
        map.set(DialogRef, dialogRef);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
        const componentRef = componentFactory.create(new DialogInjector(this.injector, map));
        if (config && config.zIndex) {
            componentRef.instance.zIndex = config.zIndex;
        }
        this.appRef.attachView(componentRef.hostView);
        const domElem = componentRef.hostView.rootNodes[0];
        document.body.appendChild(domElem);
        this.dialogComponentRef = componentRef;
        const sub = dialogRef.afterClosed.subscribe(() => {
            this.removeDialogComponentFromBody(componentRef);
            sub.unsubscribe();
        });
        componentRef.instance.onClose.subscribe(() => {
            this.removeDialogComponentFromBody(componentRef);
        });
        return dialogRef;
    }
    removeDialogComponentFromBody(componentRef) {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }
}
DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.INJECTOR)); }, token: DialogService, providedIn: "root" });
