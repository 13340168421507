import { SaleDetail, SalesAmount, SalesCreateRequest, SalesLinkBtoBResult, SalesListResponse, } from '@agent-ds/shared/interfaces/sale';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import { UserTeamInjectorProvider } from './providers/user-team-injector.provider';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./providers/user-team-injector.provider";
const SALES_API_URL = '/api/sales';
export class SalesApiService extends ApiService {
    constructor(http, userMapper) {
        super();
        this.http = http;
        this.userMapper = userMapper;
    }
    getDetail(salesId) {
        return this.http.get(`${SALES_API_URL}/${salesId}`);
    }
    getList(search) {
        const params = search ? toAsialRequestParameters(search) : undefined;
        const sales = this.http.get(SALES_API_URL + '/search', { params: (params ? params : {}) });
        return this.userMapper.getRequestAndProcessList(sales, 'saleses');
    }
    getReport(query) {
        const params = query ? toAsialRequestParameters(query) : undefined;
        return this.http.get(`${SALES_API_URL}/amount`, { params: (params ? params : {}) });
    }
    create(salesCreateRequest) {
        return this.http.post(SALES_API_URL, salesCreateRequest, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    update(sale, status, cancelStatus) {
        return this.http.put(`${SALES_API_URL}/${sale.id}`, Object.assign({}, sale, { status: status, cancelStatus: cancelStatus }), { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    linkBtoBSales(salesIds) {
        return this.http.put(`${SALES_API_URL}/bulk/invoce/btob`, { salesIds: salesIds });
    }
    linkBtoBSale(saleId) {
        return this.http.put(`${SALES_API_URL}/${saleId}/invoce/btob`, { saleId: saleId }, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
}
SalesApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SalesApiService_Factory() { return new SalesApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserTeamInjectorProvider)); }, token: SalesApiService, providedIn: "root" });
