import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import { UserTeamInjectorProvider } from './providers/user-team-injector.provider';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./providers/user-team-injector.provider";
export class JobApiService extends ApiService {
    constructor(http, userMapper) {
        super();
        this.http = http;
        this.userMapper = userMapper;
        this.jobFavoritesChanged = new Subject();
    }
    getDetail(jobId) {
        return this.http.get('/api/job/' + jobId);
    }
    getList(search) {
        const jobs = this.http.get('/api/job/search', {
            params: (search ? toAsialRequestParameters(search) : {}),
        });
        return this.userMapper.getRequestAndProcessList(jobs, 'jobs');
    }
    addJob(departmentId) {
        return this.http.post(`/api/job`, { departmentId: departmentId }, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    getSuggestions(keyword, ids, enterpriseIds) {
        return keyword || (ids && ids.length) || (enterpriseIds && enterpriseIds.length)
            ? this.http.get(`/api/job/suggest`, {
                params: { name: keyword || undefined, id: ids, enterpriseId: enterpriseIds },
            })
            : of([]);
    }
    getSuggestionsRecruitment(keyword, ids, enterpriseIds) {
        return keyword || (ids && ids.length) || (enterpriseIds && enterpriseIds.length)
            ? this.http.get(`/api/job/suggest/recruitment`, {
                params: { name: keyword || undefined, id: ids, enterpriseId: enterpriseIds },
            })
            : of([]);
    }
    getFavorites() {
        return this.http.get('/api/favorite-job/');
    }
    addFavorite(jobId) {
        return this.http.post(`/api/favorite-job/${jobId}`, null).pipe(tap(() => {
            this.jobFavoritesChanged.next({
                jobId: jobId,
                favorite: true,
            });
        }));
    }
    deleteFavorite(jobId) {
        return this.http.delete(`/api/favorite-job/${jobId}`).pipe(tap(() => {
            this.jobFavoritesChanged.next({
                jobId: jobId,
                favorite: false,
            });
        }));
    }
    getMemos(id, sort, order) {
        return this.http.get(`/api/job/${id}/memos`, { params: { sort: sort || 'registerDate', order: order || 'desc' } });
    }
    addMemo(id, memo) {
        return this.http.post(`/api/job/${id}/memos`, memo);
    }
    updateMemo(id, memo) {
        return this.http.put(`/api/job/${id}/memos/${memo.id}`, memo);
    }
    deleteMemo(id, memoId) {
        return this.http.delete(`/api/job/${id}/memos/${memoId}`);
    }
    copyJob(id) {
        return this.http.post(`/api/job/${id}/copy`, {}, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    downloadPdf(id) {
        return this.http.get(`/api/job/${id}/file/pdf`, {
            responseType: 'blob',
            observe: 'response',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    }
    downloadExcel(id) {
        return this.http.get(`/api/job/${id}/file/excel`, {
            responseType: 'blob',
            observe: 'response',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            },
        });
    }
    getJobsAsPdf(jobsIds) {
        return this.http.get(`/api/job/bulk/file/pdf`, {
            responseType: 'blob',
            observe: 'response',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
            params: (jobsIds ? toAsialRequestParameters({ jobIds: jobsIds }) : {}),
        });
    }
    ckeckJobUsers(jobIds, status) {
        return this.http.get('/api/job/check/job-user', {
            params: toAsialRequestParameters({ jobIds: jobIds, status: status }),
        });
    }
    update(id, jobData) {
        return this.http.put(`/api/job/${id}`, jobData, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    // 開催日程別メール配信取得
    getJobSettingMail(id) {
        return this.http.get(`/api/job/${id}/setting-mail`, {});
    }
    // 開催日程別メール配信更新
    updateJobSettingMail(id, jobSettingMail) {
        return this.http.put(`/api/job/${id}/setting-mail`, jobSettingMail, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
}
JobApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JobApiService_Factory() { return new JobApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserTeamInjectorProvider)); }, token: JobApiService, providedIn: "root" });
