import { ApiService } from '@agent-ds/shared/models/api-service';
import { downloadResponse } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth.service";
export class MailApiService extends ApiService {
    constructor(http, authService) {
        super();
        this.http = http;
        this.authService = authService;
        this.authService.isLogined().subscribe((logined) => {
            if (logined) {
                this.mailTemplates = this.mailTemplatePlaceholders = this.mailTemplateTypes = null;
            }
        });
    }
    getMails(model, id, from = 0, size = 1000, sort = 'timestamp', order = 'desc', keyword) {
        return this.http.get(`/api/mail/${model}/${id}`, {
            params: { from: from, size: size, sort: sort, order: order, keyword: keyword || '' },
        });
    }
    getAttachmentInfo(id) {
        return this.http.get(`/api/mail/${id}/attachment/info`);
    }
    getMailTemplateTypes() {
        if (!this.mailTemplateTypes) {
            this.mailTemplateTypes = this.http.get('/api/mail/template/types').pipe(shareReplay(1));
        }
        return this.mailTemplateTypes;
    }
    getMailTemplatePlaceholder() {
        if (!this.mailTemplatePlaceholders) {
            this.mailTemplatePlaceholders = this.http.get('/api/mail/template/placeholder').pipe(shareReplay(1));
        }
        return this.mailTemplatePlaceholders;
    }
    getMailTemplates() {
        if (!this.mailTemplates) {
            this.mailTemplates = this.http.get('/api/mail/template').pipe(shareReplay(1));
        }
        return this.mailTemplates;
    }
    getMailTemplateNames() {
        return this.http.get(`/api/mail/template/names`).pipe(shareReplay(1));
    }
    getMailTemplate(id) {
        return this.http.get(`/api/mail/template/id/${id}`).pipe(shareReplay(1));
    }
    addMailTemplate(template) {
        return this.http.post('/api/mail/template', template).pipe(tap(() => (this.mailTemplates = null)));
    }
    updateMailTemplate(id, template) {
        return this.http.put(`/api/mail/template/${id}`, template).pipe(tap(() => (this.mailTemplates = null)));
    }
    sendSalesNotification(request) {
        return this.http.post('/api/mail/sales-notification/send', request).pipe(tap(() => this.refreshEvent.emit()));
    }
    downloadAttachment(id, partId) {
        this.http
            .get(`/api/mail/${id}/attachment/${partId}/download`, { responseType: 'blob', observe: 'response' })
            .subscribe((res) => downloadResponse(res));
    }
    getSignature(model, id) {
        return this.http.get(`/api/mail/signature/${model}/${id}`);
    }
    addSignature(model, id, signature) {
        return this.http.post(`/api/mail/signature/${model}/${id}`, signature);
    }
    updateSignature(id, signature) {
        return this.http.put(`/api/mail/signature/${id}`, signature);
    }
    deleteSignature(id) {
        return this.http.delete(`/api/mail/signature/${id}`);
    }
    sendStudentMail(id, mailObj) {
        return this.http.post(`/api/mail/students/${id}/send`, mailObj).pipe(tap(() => this.refreshEvent.emit()));
    }
    sendStudentBulkMail(mailObj) {
        return this.http.post('/api/mail/bulk/students/send', mailObj).pipe(tap(() => this.refreshEvent.emit()));
    }
    sendEnterpriseMail(enterpriseId, departmentId, mailObj) {
        return this.http
            .post(`/api/mail/enterprise/${enterpriseId}/department/${departmentId}/send`, mailObj)
            .pipe(tap(() => this.refreshEvent.emit()));
    }
    sendEnterpriseResumeMail(enterpriseId, departmentId, mailObj) {
        return this.http
            .post(`/api/mail/enterprise/${enterpriseId}/department/${departmentId}/resume/send`, mailObj)
            .pipe(tap(() => this.refreshEvent.emit()));
    }
    replaceTemplate(templateType, templateObj) {
        return this.http.post(`/api/mail/template/replaced/${templateType}`, templateObj);
    }
    bulkRecommend(request) {
        return this.http.post('/api/mail/bulk/recommendation/send', request).pipe(tap(() => this.refreshEvent.emit()));
    }
    sendJobStudentsBulkMail(mailObj) {
        return this.http.post('/api/mail/job/students/bulk/send', mailObj).pipe(tap(() => this.refreshEvent.emit()));
    }
    sendJobStudentMail(id, mailObj) {
        return this.http.post(`/api/mail/job/${id}/student/send`, mailObj).pipe(tap(() => this.refreshEvent.emit()));
    }
    sendJobEnterpriseMail(jobId, enterpriseId, departmentId, mailObj) {
        return this.http
            .post(`/api/mail/job/${jobId}/enterprise/${enterpriseId}/department/${departmentId}/send`, mailObj)
            .pipe(tap(() => this.refreshEvent.emit()));
    }
    sendNgNotification(mailObj) {
        return this.http.post('/api/mail/ng-notification/send', mailObj).pipe(tap(() => this.refreshEvent.emit()));
    }
    sendSearchResult(mailObj) {
        return this.http.post(`/api/mail/bulk/students/search-result/send`, mailObj).pipe(tap(() => this.refreshEvent.emit()));
    }
}
MailApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MailApiService_Factory() { return new MailApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: MailApiService, providedIn: "root" });
